import React from 'react'
import { Link, graphql } from 'gatsby'
import Footer from '../components/footer'
import Seo from '../components/seo'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Nav from '../components/nav'

const EntryPage = ({data}) => {
  const entry = data.markdownRemark
  const image = getImage(entry.frontmatter.image)
  const side_image = getImage(entry.frontmatter.side_image)
	return (
    <div>
      <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
      <Nav />

      <div className="w-full py-36 font-verdana flex-grow-0 flex-shrink-0 basis-full relative">
        <div className="relative z-30 flex items-center justify-center text-center w-full h-full">
          <div className="w-10/12 lg:w-2/3">
            <h1 className="font-bold text-4xl lg:text-6xl text-white leading-none">{entry.frontmatter.heading}</h1>
          </div>
        </div>
        <div className="absolute -inset-0 z-20 w-full h-full bg-black opacity-25" /> 
        <div className="absolute -inset-0 z-10 w-full h-full">
          <GatsbyImage className="w-full h-full object-cover" image={image} alt="" style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} /> 
        </div>
      </div>

      <div className="w-full pt-12 pb-12">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex justify-between gap-24">
            <div className="w-full sm:w-8/12">
              <div className="max-w-none font-verdana prose prose-md lg:prose-lg">
                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
              </div>
              <div className="font-verdana text-xl font-bold text-white mt-6 bg-red py-3 px-5 inline-block">
                <Link to="/contact/">Get a free quote <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
              </div>
            </div>
            <div className="w-full sm:w-4/12 pt-12 sm:pt-0">
              {side_image !== null && 
              <figure className="mb-12">
                <GatsbyImage image={side_image} alt={entry.frontmatter.side_heading} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "contain" }} />
              </figure>
              }
              {entry.frontmatter.side_heading !== null &&
              <div className="font-verdana text-blue font-bold text-2xl">
                <h3>{entry.frontmatter.side_heading}</h3>
              </div>
              }
              {entry.frontmatter.side_text !== null && 
              <div className="font-verdana prose prose-md lg:prose-lg mt-6">
                <ul>
                {entry.frontmatter.side_text.map((entry, i) => {
                  return (
                    <li key={`side_text_${i}`}>{entry.text}</li>
                  )
                })}
                </ul>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="w-full py-12 relative">
        <div className="absolute -inset-0">
          <img src="/images/uploads/shutterstock_1083903761.jpg" alt="" className="object-cover object-top w-full h-full block" /> 
        </div>
        <div className="w-10/12 mx-auto max-w-screen-2xl relative">
          <div className="flex justify-around items-center text-center">
            <div className="w-10/12 sm:w-8/12 lg:w-8/12 xl:w-8/12">
              <div className="max-w-none font-verdana prose prose-md lg:prose-2xl text-center">
                <p>{entry.frontmatter.quote} <strong>&mdash; {entry.frontmatter.author}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
	)
}

export default EntryPage;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
				heading
        image {
					childImageSharp {
            gatsbyImageData(width: 1280)
          }
				}
        side_image {
					childImageSharp {
            gatsbyImageData(width: 640)
          }
				}
        side_heading
        side_text {
          text
        }
        quote
        author
			}
			fields {
				slug
			}
      html
		}
	}
`